<template>
  <section class="text-xs col-span-12 sm:col-span-12  md:col-span-6 xl:col-span-4 intro-y">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class=" box p-5 zoom-in scroll-ajuste">

      <div
        v-if="$can('pharmasan.dashboard.ver-consultas')"
        class="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-blue-500 rounded-full"
        @click="openModalConsulta"
      >
        <Icon
          :icon="`mdi:sql-query`"
          class="absolute text-gray-200 text-sm"
          style="bottom: 7px; right: 7px"
          v-tippy="{ content: 'Visualizar consulta' }"
        />
      </div>

      <template v-if="estado === 1">
        <div class="font-medium text-sm truncate">Ventas x Cliente</div>
        <div class="flex items-center">
          <div class="w-6/12 flex-none" @click="setEstado(2, 'evento')">
            <div class="font-normal truncate">Evento ({{$h.formatNumber(info.cantidad)}} OVs)</div>
            <div class="text-gray-600 mt-1">{{$h.formatCurrency(info.total)}}</div>
            <div class="font-normal mt-1">Costo ({{getRentabilidadGeneral}} %Rent.)</div>
            <div class="text-gray-600">{{$h.formatCurrency(getCostoTotal)}}</div>
          </div>
          <div class="w-6/12 flex-none" @click="setEstado(2, 'capita')">
            <div class="font-normal truncate">Capita ({{$h.formatNumber(infoCapita.cantidad)}} OVs)</div>
            <div class="text-gray-600 mt-1">{{$h.formatCurrency(infoCapita.total)}}</div>
            <div class="font-normal mtf-1">Costo ({{getCapitaRent}} %Rent)</div>
            <div class="text-gray-600">{{$h.formatCurrency(getCapitaCostoTotal)}}</div>
          </div>
        </div>
      </template>
      <template v-if="estado === 2">
        <div class="flex items-center justify-between w-full">
          <div class="font-medium truncate text-sm">Ventas x Cliente ({{ getNameTypeModalidad }})</div>
          <button
            type="button"
            class="btn btn-atras"
            @click="setEstado(1, typeModalidad)"
          >
            <svg style="width:12px;height:12px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
            </svg>
          </button>
        </div>
        <div class="w-full flex justify-between">
          <input
            v-model="searchNivel2"
            type="search"
            placeholder="Buscar"
            class="border border-gray-400 px-2 py-1 rounded-md dark:text-primary-1"
          >
        </div>
        <div class="w-full overflow-x-auto">
          <div class="w-full flex">
            <div class="font-bold" style="width:55%;">Cliente</div>
          </div>

          <div
            v-for="(row,k) in getItems"
            :key="k"
            class="w-full flex flex-col"
          >
            <div class="flex w-full">
              <div
                class="mb-1 text-primary-1 dark:text-white"
                style="width:55%;"
                @click.stop="setContratoSel(row.contrato)"
              >
                {{ row.contrato }}
              </div>
            </div>
            <!-- pbs -->
            <div v-if="getNameTypeModalidad == 'Evento'" class="flex w-full border-b">
              <div class="my-auto ml-2 dark:text-white" style="width:40%;">PBS</div>
              <div>
                <div class="flex w-full">
                  <div class="font-bold bg-gray-100" style="width:80px;">Cant</div>
                  <div class="font-bold bg-gray-100 text-right" style="width:150px;">Total</div>
                </div>
                <div class="flex w-full">
                  <div class="mb-1" style="width:80px;">{{ $h.formatNumber(row.cantidadpbs) }}</div>
                  <div class="mb-1 text-right" style="width:150px;">{{ $h.formatCurrency(row.totalpbs) }}</div>
                </div>
                <div class="flex w-full">
                  <div class="font-bold bg-gray-100" style="width:80px;">%Rent. </div>
                  <div class="font-bold bg-gray-100 text-right" style="width:150px;">Costo</div>
                </div>
                <div class="flex w-full  mb-2 pb-2">
                  <div class="bg-gray-100" style="width:80px;">
                    {{
                      currency(row.rentabilidadpbs, {
                        separator: '.',
                        precision: 1,
                        symbol: ''
                      }).format()
                    }}
                  </div>
                  <div class="bg-gray-100 text-right" style="width:150px;">{{ $h.formatCurrency(row.costopbs) }}</div>
                </div>
              </div>
            </div>
            <!-- no pbs -->
            <div v-if="getNameTypeModalidad === 'Evento'" class="flex w-full">
              <div class="my-auto ml-2 dark:text-white" style="width:40%;">NO PBS</div>
              <div>
                <div class="flex w-full">
                  <div class="font-bold bg-gray-100" style="width:80px;">Cant</div>
                  <div class="font-bold bg-gray-100 text-right" style="width:150px;">Total</div>
                </div>
                <div class="flex w-full">
                  <div class="mb-1" style="width:80px;">{{ $h.formatNumber(row.cantidadnopbs) }}</div>
                  <div class="mb-1 text-right" style="width:150px;">{{ $h.formatCurrency(row.totalnopbs) }}</div>
                </div>
                <div class="flex w-full">
                  <div class="font-bold bg-gray-100" style="width:80px;">%Rent.</div>
                  <div class="font-bold bg-gray-100 text-right" style="width:150px;">Costo</div>
                </div>
                <div class="flex w-full  mb-2 border-b pb-2">
                  <div class="bg-gray-100" style="width:80px;">
                    {{
                      currency(row.rentabilidadnopbs, {
                        separator: '.',
                        precision: 1,
                        symbol: ''
                      }).format()
                    }}
                  </div>
                  <div class="bg-gray-100 text-right" style="width:150px;">{{ $h.formatCurrency(row.costonopbs) }}</div>
                </div>
              </div>
            </div>
            <!-- capita -->
            <div v-if="getNameTypeModalidad == 'Capita'" class="flex w-full border-b">
              <div class="my-auto ml-2 dark:text-white" style="width:40%;"></div>
              <div>
                <div class="flex w-full">
                  <div class="font-bold bg-gray-100" style="width:80px;">Cant</div>
                  <div class="font-bold bg-gray-100 text-right" style="width:150px;">Total</div>
                </div>
                <div class="flex w-full">
                  <div class="mb-1" style="width:80px;">{{ $h.formatNumber(row.cantidad) }}</div>
                  <div class="mb-1 text-right" style="width:150px;">{{ $h.formatCurrency(row.total) }}</div>
                </div>
                <div class="flex w-full">
                  <div class="font-bold bg-gray-100" style="width:80px;">%Rent.</div>
                  <div class="font-bold bg-gray-100 text-right" style="width:150px;">Costo</div>
                </div>
                <div class="flex w-full  mb-2 pb-2">
                  <div class="bg-gray-100" style="width:80px;">
                    {{
                      currency(row.rentabilidad, {
                        separator: '.',
                        precision: 1,
                        symbol: ''
                      }).format()
                    }}
                  </div>
                  <div class="bg-gray-100 text-right" style="width:150px;">{{ $h.formatCurrency(row.costo) }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </template>
      <template v-if="estado === 3">
        <div class="flex justify-between items-center w-full">
          <div class="font-medium truncate text-sm">Ventas x Cliente ({{ getNameTypeModalidad }})</div>
          <button
            type="button"
            class="btn btn-atras"
            @click="setEstado(2, typeModalidad)"
          >
            <svg style="width:12px;height:12px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
            </svg>
          </button>
        </div>
        <div class="w-full flex justify-between">
          <input
            v-model="searchNivel3"
            type="search"
            class="border border-gray-300 px-2 py-1 rounded-md dark:text-primary-1"
            placeholder="Buscar"
          >
        </div>

        <div class="w-full flex items-center text-sm">
          <div class="table_cards w-full">
            <div class="flex w-full">
              <div style="width:55%;" class="font-bold">Dpto</div>
            </div>
            <template
              class="hover:bg-gray-300" v-for="(row,k) in getResumen"
              :key="k"
            >
              <div class="w-full flex">
                <!-- @click="setPbsSel(row.label)" -->
                <div
                  class="text-left dark:text-white text-primary-1"
                  style="width:55%;"
                  @click="row.visible = !row.visible"
                >
                  {{row.label}}
                </div>
              </div>
              <div class="w-full flex border-b">
                <!-- @click="setPbsSel(row.label)" -->
                <div
                  class="text-left dark:text-white ml-2 my-auto"
                  style="width:40%;"
                  @click="row.visible = !row.visible"
                >
                  PBS
                </div>
                <div>
                  <div class="flex w-full">
                    <div class="font-bold bg-gray-100 text-right" style="width:70px;">Cant</div>
                    <div class="font-bold bg-gray-100 text-right" style="width:150px;">Total</div>
                  </div>
                  <div class="flex w-full">
                    <div class="text-right" style="width:70px;">
                      {{$h.formatNumber(row.cantidadpbs)}}
                    </div>
                    <div class="text-right" style="width:150px;">
                      {{$h.formatCurrency(row.totalpbs)}}
                    </div>
                  </div>
                  <div class="flex w-full">
                    <div class="font-bold bg-gray-100 text-right" style="width:70px;">%Rent.</div>
                    <div class="font-bold bg-gray-100 text-right" style="width:150px;">Costo</div>
                  </div>
                  <div class="flex w-full  mb-2 pb-2">
                    <div class="bg-gray-100 text-right" style="width:70px;">{{currency(row.rentapbs, { separator: '.', precision: 1, symbol: '' }).format()}}</div>
                    <div class="bg-gray-100 text-right" style="width:150px;">{{$h.formatNumber(row.costopbs)}}</div>
                  </div>
                </div>
              </div>
              <div class="w-full flex border-b">
                <!-- @click="setPbsSel(row.label)" -->
                <div
                  class="text-left dark:text-white ml-2 my-auto"
                  style="width:40%;"
                  @click="row.visible = !row.visible"
                >
                  NO PBS
                </div>
                <div>
                  <div class="flex w-full">
                    <div class="font-bold bg-gray-100 text-right" style="width:70px;">Cant</div>
                    <div class="font-bold bg-gray-100 text-right" style="width:150px;">Total</div>
                  </div>
                  <div class="flex w-full">
                    <div class="text-right" style="width:70px;">
                      {{$h.formatNumber(row.cantidadnopbs)}}
                    </div>
                    <div class="text-right" style="width:150px;">
                      {{$h.formatCurrency(row.totalnopbs)}}
                    </div>
                  </div>
                  <div class="flex w-full">
                    <div class="font-bold bg-gray-100 text-right" style="width:70px;">%Rent.</div>
                    <div class="font-bold bg-gray-100 text-right" style="width:150px;">Costo</div>
                  </div>
                  <div class="flex w-full  mb-2 pb-2">
                    <div class="bg-gray-100 text-right" style="width:70px;">{{currency(row.rentanopbs, { separator: '.', precision: 1, symbol: '' }).format()}}</div>
                    <div class="bg-gray-100 text-right" style="width:150px;">{{$h.formatNumber(row.costonopbs)}}</div>
                  </div>
                </div>
              </div>
              <div v-if="row.visible" class="w-full flex flex-col">
                <div class="flex w-full">
                  <div style="width:40%;" class="font-bold pl-2">Sede</div>
                  <div style="width:30%;" class="text-right font-bold">Vlr Factura</div>
                  <div style="width:30%;" class="text-right font-bold">Vlr Costo</div>
                </div>
                <div v-for="(row3,m) in row.children" :key="m" class="w-full flex">
                  <div style="width:40%;" class="text-left pl-2">{{row3.label}}</div>
                  <div style="width:30%;" class="text-right">{{$h.formatCurrency(row3.total)}}</div>
                  <div style="width:30%;" class="text-right">{{$h.formatCurrency(row3.costo)}}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </section>

  <!-- Modal para visualizar la consulta -->
  <Dialog
    v-model:visible="modalVisible"
    dismissableMask
    closeOnEscape
    maximizable
    modal
    :style="{ width: '60rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      header: {
        style: 'padding: 0.75rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Consulta - ` + info.name }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <TabView>
      <TabPanel header="Query Evento">
        <highlightjs
          autodetect
          :code="info.query"
        />
      </TabPanel>
      <TabPanel header="Query Capita">
        <highlightjs
          autodetect
          :code="infoCapita.query"
        />
      </TabPanel>
    </TabView>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalConsulta" class="p-button-xs p-button-danger"/>
    </template>
  </Dialog>
</template>

<script>
import Icon from '@/components/Icon/index.vue'
import 'highlight.js/styles/atom-one-dark.min.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

import { defineComponent, ref, watchEffect } from 'vue'
import * as parameters from './Hook'
import currency from 'currency.js'
export default defineComponent({
  name: 'CardConsumoBodegas',
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  components: {
    Icon,
    highlightjs: hljsVuePlugin.component
  },
  setup (props, context) {
    const modalVisible = ref(false)

    const openModalConsulta = () => {
      modalVisible.value = true
    }

    /* Cerrar el modal */
    const closeModalConsulta = () => {
      modalVisible.value = false
    }

    watchEffect(() => {
      parameters.fetchFacturacion({
        year: props.year,
        month: props.month
      })
      parameters.fetchFacturacionCapita({
        year: props.year,
        month: props.month
      })
    })
    return {
      currency,
      ...parameters,
      modalVisible,
      openModalConsulta,
      closeModalConsulta
    }
  }
})
</script>

<style scoped>
.table_cards{
  text-align: start;
  font-size: 0.7rem;
  margin-top: 10px;
  line-height: 1.1rem;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 280px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
</style>
